import React from 'react';

import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { Button } from '@atomic/atm.button';
import { GlobalStyled } from '@atomic/atm.globals/global-style';
import { Col, Grid, Row, GridStyled } from '@atomic/atm.grid';
import { Separator } from '@atomic/atm.separator';
import { Body, H2 } from '@atomic/atm.typography';
import { Header } from '@atomic/org.header';
import { Page404ContentStyled } from '@components/404.page.style';

interface NotFoundPageProps {
  // eslint-disable-next-line no-undef
  data: GatsbyTypes.getHeaderQuery;
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => {
  const headeData = data.allMarkdownRemark.edges[0].node.frontmatter.header;

  return (
    <>
      <Header data={headeData} />

      <Separator type="Large" />
      <Grid>
        <Row hAlign="center">
          <Page404ContentStyled xs={12} sm={12} md={12} lg={7} xl={6}>
            <Separator type="XXLarge" />
            <H2>Erro 404, página não encontrada!</H2>
            <Separator type="Small" />
            <Body>
              A página que você procura, não existe ou foi apagada, confira se a
              url digitada está correta ou se não volte para a Home.
            </Body>
            <Separator type="SemiLarge" />
            <Link to="/">
              <Button kind="callToAction">Voltar para home</Button>
            </Link>
          </Page404ContentStyled>

          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <Separator type="XLarge" />
            <StaticImage src="../images/404-illustration.svg" alt="" />
          </Col>
        </Row>
      </Grid>
      <GlobalStyled />
      <GridStyled />
    </>
  );
};

export const pageQuery = graphql`
  query getHeader {
    allMarkdownRemark(filter: { frontmatter: { title: { eq: "Settings" } } }) {
      edges {
        node {
          frontmatter {
            header {
              logo
            }
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
